/* tables */
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: none;
}

.ant-pagination-item-active a {
  color: #3d4042;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: none;
}

.ant-table-pagination.ant-pagination {
  float: none;
}

.ant-pagination-item a:hover,
.ant-pagination-item a:link .ant-pagination-item a:visited,
.ant-pagination-item a:focus {
  color: #3d4042;
}

.ant-pagination-item {
  display: inline-block;
  color: #3d4042;
  cursor: pointer;
  font-size: 12px;
  border-radius: 3px;
  border: none;
  padding: 0px;
  margin: 0 5px;
  width: 22px;
  min-width: 22px;
  height: 22px;
  line-height: 22px;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}

.ant-pagination-item-active {
  display: inline-block;
  cursor: pointer;
  color: #3d4042;
  font-size: 12px;
  border-radius: 3px;
  padding: 0px;
  margin: 0 5px;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
  background-color: #e4ebf2;
}

.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: #3d4042;
}

/*.ant-table {*/
/*  font-size: 12px;*/
/*  font-family: Roboto, sans-serif;*/
/*}*/

/*.ant-table-body {*/
/*  overflow-x: auto;*/
/*}*/

/*.ant-form-item {*/
/*  margin-bottom: 14px;*/
/*}*/

/*.ant-table-thead > tr > th {*/
/*  background: #f0f4f8;*/
/*  position: relative;*/
/*  padding: 10px 16px;*/
/*  border-bottom: none;*/
/*  border-top: 1px solid #c0ccd7;*/
/*}*/

/*.ant-table-tbody .ant-table-row {*/
/*  position: relative;*/
/*  cursor: pointer;*/
/*  font-style: normal;*/
/*  font-weight: normal;*/
/*  font-size: 12px;*/
/*  line-height: 20px;*/
/*  color: #3d4042;*/
/*  height: 40px;*/
/*}*/

/*.ant-table-tbody .ant-table-row:nth-child(even) {*/
/*  background-color: rgba(228, 235, 242, 0.3);*/
/*  font-size: 12px;*/
/*}*/

/*.ant-table-tbody .ant-table-row:nth-child(2n) {*/
/*  background: rgba(228, 235, 242, 0.3);*/
/*}*/

/*.ant-table.ant-table-scroll-position-left .ant-table-fixed-left {*/
/*  box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);*/
/*}*/

/*.ant-table table {*/
/*  width: 100%;*/
/*}*/

/*.ant-table-fixed-left table {*/
/*  width: auto;*/
/*}*/

/*.ant-table-tbody > tr > td {*/
/*  border-bottom: none;*/
/*}*/

/*.ant-table-thead > tr > th,*/
/*.ant-table-tbody > tr > td {*/
/*  overflow: hidden;*/
/*  text-overflow: ellipsis;*/
/*  white-space: nowrap;*/
/*}*/

/*.ant-table-thead > tr > th {*/
/*  padding: 6px 16px;*/
/*}*/

/*.ant-table-tbody > tr > td {*/
/*  padding: 11px 16px;*/
/*}*/

/*.ant-list-split .ant-list-header {*/
/*  border-bottom: none;*/
/*  padding-top: 0;*/
/*  border-top: 1px solid #c0ccd7;*/
/*}*/

/*.ant-list-header .ant-row {*/
/*  background-color: #f0f4f8;*/
/*  padding: 10px 16px;*/
/*}*/

/*.ant-table-row-color-light-red {*/
/*  background-color: #f5222d14 !important;*/
/*}*/

/*.ant-table-row-color-light-grey {*/
/*  background-color: #80808085 !important;*/
/*}*/

.ant-list-header .ant-row,
.ant-list-item .ant-row {
  font-size: 12px;
}

.ant-card {
  font-size: 13px;
}

/*.ant-form-item-label {*/
/*  line-height: inherit;*/
/*  white-space: normal;*/
/*  text-align: left;*/
/*}*/

/*.ant-form-item-control-wrapper {*/
/*  position: relative;*/
/*}*/

/*.ant-form-explain {*/
/*  position: absolute;*/
/*}*/

/*.ant-form-explain,*/
/*.ant-form-extra {*/
/*  margin-top: 0;*/
/*}*/

/* buttons */
.ant-btn {
  border-radius: 6px;
  font-size: 13px;
  line-height: normal;
  border: 1px solid #adb8c3;
  box-shadow: none;
  color: #838d96;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.ant-btn:hover {
  box-shadow: 0px 2px 5px rgba(189, 189, 189, 0.31);
  border: 1px solid #adb8c3;
  color: #838d96;
}

.ant-btn:active,
.ant-btn:focus {
  border: 1px solid #adb8c3;
  background: #e4ebf2;
  color: #838d96;
}

.ant-btn[disabled],
.ant-btn[disabled]:hover {
  border: 1px solid #e4ebf2;
  background: white;
  color: #c0ccd7;
}

.ant-btn-danger:hover {
  color: white;
  border-color: red;
  box-shadow: 0px 2px 5px rgba(148, 2, 16, 0.31);
}

.ant-btn-plain {
  border-radius: 0;
  font-size: 13px;
  line-height: normal;
  border: none;
  box-shadow: none;
  color: #2770ff;
  background: white;
  display: inline-flex;
  align-items: center;
}

.ant-btn-plain a {
  text-decoration: none;
}

.ant-btn-plain .icon {
  margin: 0 5px;
}

.ant-btn-plain:hover {
  box-shadow: none;
  border: none;
  color: #2770ff;
}

.ant-btn-plain:active,
.ant-btn-plain:focus {
  border: none;
  background: white;
  color: #2770ff;
}

.ant-btn-plain[disabled],
.ant-btn-plain[disabled]:hover {
  border: none;
  background: white;
  color: #c0ccd7;
}

.ant-btn-primary {
  background: #2770ff;
  box-shadow: none;
  border: none;
  color: white;
  box-sizing: border-box;
}

.ant-btn-primary:hover {
  background: #054dd9;
  box-shadow: 0px 2px 5px rgba(5, 45, 148, 0.31);
  color: white;
  border: none;
}

.ant-btn-primary:active,
.ant-btn-primary:focus {
  color: white;
  border: none;
  background: #054dd9;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover {
  background: #a1c1ff;
  color: white;
}

/* feedbacks */
.ant-form-item-children {
  position: static;
}

/* inputs */
.ant-input {
  border: 1px solid #c0ccd7;
  box-sizing: border-box;
  border-radius: 3px;
  height: 32px;
  color: #3d4042;
  font-size: 14px;
  padding: 11px 10px;
  transition: box-shadow 0.5s;
}

.ant-input:focus {
  box-shadow: 0 0 0 2px #a1c1ff;
  border-color: #2770ff;
}

.ant-input:focus:hover {
  border: 1px solid #2770ff;
}

.ant-input:hover {
  border: 1px solid #697279;
}

.has-error .ant-input:focus {
  box-shadow: 0 0 0 2px #ffbcb8;
}

.ant-input-sm {
  height: 24px;
}
/*.has-error .ant-form-item-label label {*/
/*  color: red;*/
/*}*/

/*.has-error .ant-form-explain,*/
/*.has-error .ant-form-split {*/
/*  color: #e9180a;*/
/*  font-size: 9px;*/
/*}*/

/*.ant-form-item-label {*/
/*  line-height: normal;*/
/*  overflow: initial;*/
/*  margin-bottom: 4px;*/
/*}*/

/*.ant-form-item-label label {*/
/*  color: #3d4042;*/
/*  font-size: 14px;*/
/*}*/

/*.ant-form-item-control {*/
/*  line-height: normal;*/
/*}*/

/*.ant-form-item-label label::after {*/
/*  content: '';*/
/*}*/

/*.ant-form-item-label {*/
/*  font-family: Roboto, sans-serif;*/
/*}*/

/*.ant-form-item-label label.ant-form-item-required::after {*/
/*  display: inline-block;*/
/*  content: '*';*/
/*  font-family: Roboto, sans-serif;*/
/*  line-height: 1;*/
/*  font-size: 14px;*/
/*  color: #f5222d;*/
/*  margin-right: 0;*/
/*}*/

/*.ant-form-item-label label.ant-form-item-required:before {*/
/*  content: '';*/
/*  margin-right: 0;*/
/*}*/

/*.ant-form-item-children*/
/*  .ant-form-item-children-icon*/
/*  .anticon.anticon-close-circle*/
/*  svg {*/
/*  display: none;*/
/*}*/

/*.ant-form-item-children*/
/*  .ant-form-item-children-icon*/
/*  .anticon.anticon-close-circle {*/
/*  fill: red;*/
/*}*/

/*.ant-form-item-children*/
/*.ant-form-item-children-icon*/
/*.anticon.anticon-close-circle::before {*/
/*    content: '';*/
/*    display: block;*/
/*    width: 12px;*/
/*    height: 12px;*/
/*    background-color: red;*/
/*    mask-image: url('/img/icons/x-12.svg');*/
/*}*/

/*selects */
/*.ant-select {*/
/*  width: 100%;*/
/*}*/

/*.ant-select-selection {*/
/*  border: 1px solid #c0ccd7;*/
/*  box-sizing: border-box;*/
/*  border-radius: 3px;*/
/*  color: #3d4042;*/
/*  font-size: 14px;*/
/*  transition: box-shadow 0.5s, margin 0.1s;*/
/*}*/

/*.ant-select-focused !*.ant-select-selection*!*/
/*{*/
/*    box-shadow: 0 0 0 2px #a1c1ff;*/
/*    border-color: #2770ff;*/
/*    margin: 0 2px;*/
/*}*/

/*.ant-select-focused .ant-select-selection:hover {*/
/*    border-color: #2770ff;*/
/*}*/

/*.has-error .ant-select-focused .ant-select-selection:hover {*/
/*  border-color: #e9170a;*/
/*}*/

/*.has-error .ant-select-selection:hover {*/
/*  border-color: #e9170a;*/
/*}*/

/*.ant-select-selection:hover {*/
/*  border-color: #697279;*/
/*}*/

/*.has-error .ant-select-selection:focus {*/
/*  box-shadow: 0 0 0 2px #ffbcb8;*/
/*}*/

/*.ant-select-arrow .anticon.ant-select-arrow-icon svg {*/
/*  display: none;*/
/*}*/

/*.ant-select-arrow .anticon.ant-select-arrow-icon::before {*/
/*  content: '';*/
/*  display: block;*/
/*  width: 12px;*/
/*  height: 12px;*/
/*  background: url('/img/icons/arrow-down-12.svg');*/
/*}*/

/*.has-error .ant-select-arrow .anticon.ant-select-arrow-icon::before {*/
/*  content: '';*/
/*  display: block;*/
/*  width: 12px;*/
/*  height: 12px;*/
/*  background: red;*/
/*  mask-image: url('/img/icons/arrow-down-12.svg');*/
/*}*/

/*.ant-select-open .ant-select-arrow .anticon.ant-select-arrow-icon::before {*/
/*  content: '';*/
/*  display: block;*/
/*  width: 12px;*/
/*  height: 12px;*/
/*  background: url('/img/icons/arrow-up-12.svg');*/
/*}*/

/*.ant-select-dropdown--empty .ant-select-dropdown-menu-item {*/
/*  padding: 5px 10px;*/
/*}*/

/*.has-error*/
/*  .ant-select-open*/
/*  .ant-select-arrow*/
/*  .anticon.ant-select-arrow-icon::before {*/
/*  content: '';*/
/*  display: block;*/
/*  width: 12px;*/
/*  height: 12px;*/
/*  background: red;*/
/*  mask-image: url('/img/icons/arrow-up-12.svg');*/
/*}*/

/* input number */
.ant-input-number {
  border: 1px solid #c0ccd7;
  box-sizing: border-box;
  border-radius: 3px;
  color: #3d4042;
  font-size: 14px;
  transition: box-shadow 0.5s;
}

.ant-input-number-focused {
  box-shadow: 0 0 0 2px #a1c1ff;
  border-color: #2770ff;
}

.ant-input-number.ant-input-number-focused:hover {
  border-color: #2770ff;
}

.has-error .ant-input-number-focused:hover {
  border-color: #e9170a;
}

.has-error .ant-input-number:hover {
  border-color: #e9170a;
}

.ant-input-number:hover {
  border-color: #697279;
}

.has-error .ant-input-number-focused {
  box-shadow: 0 0 0 2px #ffbcb8;
}

.ant-input-number-handler-down-inner,
.ant-input-number-handler-up-inner {
  width: 16px;
  height: 16px;
}

.ant-input-number-handler-up-inner svg {
  display: none;
}

.ant-input-number-handler-up-inner {
  position: relative;
  left: 1px;
  margin-top: 0;
  top: 0;
}

.ant-input-number-handler-down-inner {
  position: relative;
  left: 1px;
  margin-top: 0;
  top: 0;
}

.ant-input-number-handler-up-inner::before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  background: #adb8c3;
  mask-image: url('/img/icons/arrow-up-16.svg');
}

.ant-input-number-handler-down-inner svg {
  display: none;
}

.ant-input-number-handler-down-inner::before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  background: #adb8c3;
  mask-image: url('/img/icons/arrow-down-16.svg');
}

/* checkbox */
.ant-checkbox-inner {
  border: 1.5px solid #98a3ad;
  box-sizing: border-box;
  border-radius: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: #2770ff;
  border-color: #2770ff;
}

.ant-checkbox-checked .ant-checkbox-inner:hover {
  border-color: #2770ff;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  display: none;
}

.ant-checkbox-checked .ant-checkbox-inner::before {
  content: '';
  position: absolute;
  left: -1px;
  top: -1px;
  width: 16px;
  height: 16px;
  background-size: contain;
  background: url('/img/icons/checkbox-mark.svg') no-repeat center;
}

.ant-checkbox {
  outline: none !important;
}

/* calendar */
/*.ant-calendar-picker-input.ant-input {*/
/*  display: inline-flex;*/
/*}*/

/*.ant-calendar-picker .ant-calendar-picker-icon {*/
/*  height: 17px;*/
/*  width: 18px;*/
/*  right: 8px;*/
/*  margin-top: -9px;*/
/*}*/

/*.ant-calendar-picker .ant-calendar-picker-icon svg {*/
/*  display: none;*/
/*}*/

/*.ant-calendar-picker .ant-calendar-picker-clear {*/
/*  height: 20px;*/
/*  width: 25px;*/
/*  right: 5px;*/
/*  margin-top: -11px;*/
/*  padding: 4px;*/
/*  box-sizing: border-box;*/
/*}*/

/*.ant-calendar-picker.ant-calendar-picker-small .ant-calendar-picker-clear {*/
/*  height: 21px;*/
/*  width: 24px;*/
/*  margin-top: -10px;*/
/*}*/

/*.ant-calendar-picker .ant-calendar-picker-clear svg {*/
/*  display: none;*/
/*}*/

/*.ant-calendar-picker .ant-calendar-picker-clear::before {*/
/*  content: '';*/
/*  display: block;*/
/*  width: 16px;*/
/*  height: 16px;*/
/*  background: url('/img/icons/x-12.svg') no-repeat;*/
/*  background-size: cover;*/
/*}*/

/*.ant-calendar-picker.ant-calendar-picker-small*/
/*  .ant-calendar-picker-clear::before {*/
/*  width: 12px !important;*/
/*  height: 12px !important;*/
/*}*/

/*.ant-calendar-picker .ant-calendar-picker-icon::before {*/
/*  content: '';*/
/*  display: block;*/
/*  width: 17px;*/
/*  height: 18px;*/
/*  background: url('/img/icons/calendar-16.svg') no-repeat;*/
/*  background-size: cover;*/
/*}*/

/*.ant-calendar-picker.ant-calendar-picker-small*/
/*  .ant-calendar-picker-icon::before {*/
/*  width: 16px;*/
/*  height: 16px;*/
/*  background-size: contain;*/
/*}*/

/*.ant-calendar .ant-calendar-ok-btn {*/
/*  background: #2770ff;*/
/*  box-shadow: none;*/
/*  border: none;*/
/*  color: white;*/
/*  box-sizing: border-box;*/
/*}*/

/*.ant-calendar-picker-input.ant-input {*/
/*  padding: 4px 11px;*/
/*}*/

/*.ant-calendar .ant-calendar-ok-btn:hover {*/
/*  background: #054dd9;*/
/*  box-shadow: 0px 2px 5px rgba(5, 45, 148, 0.31);*/
/*  color: white;*/
/*  border: none;*/
/*}*/

/*.ant-calendar .ant-calendar-ok-btn:active,*/
/*.ant-calendar .ant-calendar-ok-btn:focus {*/
/*  color: white;*/
/*  border: none;*/
/*  background: #054dd9;*/
/*}*/

/*.ant-calendar .ant-calendar-ok-btn[disabled],*/
/*.ant-calendar .ant-calendar-ok-btn[disabled]:hover {*/
/*  background: #a1c1ff;*/
/*  color: white;*/
/*}*/

/*range picker*/
/*.ant-calendar-picker {*/
/*  width: 100%;*/
/*}*/

/*.ant-calendar-range-picker-separator,*/
/*.ant-calendar-range-middle {*/
/*  visibility: hidden;*/
/*  width: 50px;*/
/*  position: relative;*/
/*  display: none;*/
/*  justify-content: center;*/
/*}*/

/*.ant-calendar-range-picker-input {*/
/*  text-align: left;*/
/*}*/

/*.ant-calendar-picker-small .ant-calendar-range-picker-input {*/
/*  font-size: 13px;*/
/*  line-height: 1;*/
/*  padding: 0;*/
/*}*/

/*.ant-calendar-picker-small .ant-calendar-range-picker-input::placeholder {*/
/*  color: #bfbfbf;*/
/*}*/

/*.ant-calendar-picker-small .ant-calendar-picker-input.ant-input {*/
/*  padding: 4px 8px;*/
/*}*/

/*.ant-calendar-range-picker-input::placeholder {*/
/*  color: #98a3ad;*/
/*}*/

/*.ant-calendar-range .ant-calendar-input::placeholder,*/
/*.ant-calendar-range .ant-calendar-time-picker-input::placeholder {*/
/*  color: #98a3ad;*/
/*}*/

/*.ant-calendar-range-picker-separator::before {*/
/*  content: '';*/
/*  visibility: visible;*/
/*  display: block;*/
/*  width: 17px;*/
/*  height: 18px;*/
/*  background: url('/img/icons/calendar-16.svg') no-repeat;*/
/*  background-size: cover;*/
/*  position: absolute;*/
/*  left: -20px;*/
/*  top: 2px;*/
/*}*/

/*.ant-calendar-range-picker-separator::after,*/
/*.ant-calendar-range-middle::after {*/
/*  content: '—';*/
/*  visibility: visible;*/
/*  display: inline-block;*/
/*  color: #c0ccd7;*/
/*  position: relative;*/
/*  left: -6px;*/
/*}*/

/* Spinner */
/*.ant-spin-dot.ant-spin-dot-spin {*/
/*  width: 30px;*/
/*  height: 30px;*/
/*  background: url('/img/icons/spinner.png') no-repeat;*/
/*  background-size: cover;*/
/*}*/

/*.ant-spin-dot.ant-spin-dot-spin * {*/
/*  display: none;*/
/*}*/

/*.ant-spin-nested-loading > div > .ant-spin {*/
/*  max-height: initial;*/
/*}*/

/* modals */
.ant-modal-header {
  padding: 12px 16px;
  border-color: #c0ccd7;
}

.ant-modal-footer {
  border-color: #c0ccd7;
  padding: 16px;
}

.ant-modal-title {
  color: #3d4042;
  font-weight: 500;
}

.ant-modal-close-x {
  background: url('/img/icons/x-12.svg') no-repeat;
  background-position: 50%;
  width: 47px;
  height: 47px;
}

.ant-modal-close-x > * {
  display: none;
}

/* Notification */

.ant-notification-close-icon {
  width: 12px;
  height: 12px;
  background: url('/img/icons/x-12.svg') no-repeat;
  background-size: contain;
}

.ant-notification-close-icon svg {
  display: none;
}

/*.ant-calendar-picker {*/
/*  width: 100% !important;*/
/*}*/

/*.ant-select-dropdown {*/
/*  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);*/
/*}*/

/*.has-error.has-feedback .ant-form-item-children-icon {*/
/*  transform: translateY(-50%);*/
/*  margin-top: initial;*/
/*  line-height: initial;*/
/*  animation: none;*/
/*}*/

/*.ant-select-tree-node-content-wrapper {*/
/*  color: #3d4042;*/
/*}*/

/*.ant-select-dropdown-menu-item {*/
/*  text-overflow: unset;*/
/*  white-space: normal;*/
/*  border-bottom: 1px solid #f0f4f8;*/
/*  color: #3d4042;*/
/*}*/

/*.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-disabled {*/
/*  color: rgba(0, 0, 0, 0.25);*/
/*}*/

/*.ant-select-selection__clear .anticon-close-circle svg {*/
/*  display: none;*/
/*}*/

/*.ant-select-selection__clear .anticon-close-circle {*/
/*  position: relative;*/
/*  width: 12px;*/
/*  height: 12px;*/
/*  z-index: 2;*/
/*  border-radius: 50%;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  overflow: hidden;*/
/*  padding: 3px;*/
/*  top: -3px;*/
/*  left: -3px;*/
/*  box-sizing: content-box;*/
/*}*/

/*.ant-select-selection__clear .anticon-close-circle:hover {*/
/*  background: #e6eaee;*/
/*}*/

/*.ant-select-selection__clear .anticon-close-circle::before {*/
/*  content: '';*/
/*  display: block;*/
/*  height: 8px;*/
/*  width: 8px;*/
/*  background: url('/img/icons/x-12.svg') no-repeat;*/
/*  background-size: cover;*/
/*}*/

/*.ant-select-selection .ant-select-selection-selected-value {*/
/*  width: 100%;*/
/*  text-align: left;*/
/*}*/

/*.ant-select-auto-complete.ant-select .ant-select-selection--single {*/
/*  height: 100%;*/
/*}*/

/*.ant-select-selection .ant-select-selection__rendered::after {*/
/*  display: none;*/
/*}*/

/*.ant-select-search--inline {*/
/*  top: 0;*/
/*  left: 0;*/
/*}*/

/*.ant-select-search--inline .ant-select-search__field__wrap {*/
/*  height: initial;*/
/*  min-height: 100%;*/
/*}*/

/*.ant-select-selection--multiple .ant-select-selection__rendered,*/
/*.ant-select-selection__rendered {*/
/*  height: 100%;*/
/*  position: static;*/
/*}*/

/*.ant-select-search--inline .ant-select-search__field {*/
/*  padding-left: 10px;*/
/*}*/

/*.ant-select-selection__placeholder,*/
/*.ant-select-search__field__placeholder {*/
/*  margin-top: initial;*/
/*  transform: translateY(-50%);*/
/*  margin-left: 10px;*/
/*}*/

/*.ant-select-selection--multiple .ant-select-selection__placeholder {*/
/*  margin-left: 10px;*/
/*}*/

/*.ant-select-selection--multiple .ant-select-selection__choice {*/
/*  background: #f0f4f8;*/
/*  border: none;*/
/*  border-radius: 3px;*/
/*  font-size: 12px;*/
/*  box-shadow: 0 1px 1px rgba(5, 45, 148, 0.31);*/
/*}*/

/*:root .ant-select-selection--multiple .ant-select-selection__choice__remove {*/
/*  background: url('/img/icons/x-12.svg') no-repeat;*/
/*  height: 8px;*/
/*  width: 8px;*/
/*  top: 50%;*/
/*  transform: translateY(-50%);*/
/*  background-size: cover;*/
/*}*/

/*:root .ant-select-selection--multiple .ant-select-selection__choice__remove i {*/
/*  display: none;*/
/*}*/

.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background-color: #e6f7ff;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #e6f7ff;
}

.ant-select-item-option-content {
  text-overflow: unset;
  white-space: normal;
}

.ant-select-item-option {
  border-bottom: 1px solid #f0f4f8;
  color: #3d4042;
}
/* popover */
.ant-popover.without-icon .ant-popover-message-title {
  padding: 0;
}

.ant-popover .ant-popover-arrow {
  display: none;
}

.popover .ant-popover-arrow {
  display: none;
}
.popover .ant-popover-inner {
  background-color: #f0f4f8;
  box-shadow: 0 2px 5px rgba(5, 45, 148, 0.31);
  border-radius: 4px;
  color: #3d4042;
}
.popover .ant-popover-inner-content {
  padding: 8px 10px;
}

.ant-popover-title {
  padding: 0 10px;
  min-height: 25px;
  display: flex;
  align-items: center;
  border-color: rgba(5, 45, 148, 0.1);
  color: #3d4042;
}

.ant-input-number-handler-up-inner::before {
  -webkit-mask-size: 10px;
  mask-size: 10px;
  width: 10px;
  height: 10px;
  margin-top: 1px;
  margin-left: 2px;
}

.ant-input-number-handler-down-inner::before {
  -webkit-mask-size: 10px;
  mask-size: 10px;
  width: 10px;
  height: 10px;
  margin-top: 8px;
  margin-left: 2px;
}

.ant-picker-suffix .anticon-calendar::before {
  height: 16px !important;
}

.ant-input-sm {
  padding: 0px 7px !important;
}

.ant-select-tree .ant-select-tree-node-content-wrapper {
  padding: 3px 5px;
}

.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background-color: #e6f7ff !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px;
}

.ant-picker {
  border-radius: 4px;
}

.ant-picker-dropdown {
  z-index: 99999999;
}
