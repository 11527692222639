@import './reset.css';
@import '../fonts/Roboto/index.css';
@import './antd.css';

body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  color: #3d4042;
  min-width: 1024px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 24px;
  margin-bottom: 0;
}
h2 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}
h3 {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.ReactVirtualized__Grid {
  outline: none;
}

.primary-background {
  background: rgba(228, 235, 242, 0.3);
}

.span-delete-color {
  color: #ff7875;
}

.link-text {
  text-decoration: underline;
  color: #4370ff;
}

.vertical-align-top {
  vertical-align: top !important;
}

.pointer {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 7px !important;
  height: 7px !important;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(13deg, #bdbdbd 14%, #d9d9d9 64%) !important;
  border-radius: 2px !important;
}
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(13deg, #d9d9d9 14%, #bdbdbd 64%) !important;
}
::-webkit-scrollbar-track {
  background: #ffffff !important;
  border-radius: 2px !important;
  box-shadow: inset 7px 10px 12px #f0f0f0 !important;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #2770ff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #2770ff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.background-light-blue-important {
  background: #e6f7ff !important;
}
